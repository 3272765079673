import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    initialize() {
    this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) return;
                let msgDate = entry.target.querySelector('span.chat-message-timestamp').innerText;
                msgDate = msgDate.replace(msgDate.substring(msgDate.indexOf('-') - 6, msgDate.indexOf('-')), "");
                document.querySelector('div.chatDate > span.chatbox-datenow').innerText = msgDate;
            })
    },
    {
        root: this.element.parentElement,
        rootMargin: "-0px 0px -300px 0px",
        threshold: 0.5,
        delay: 250
    })

    this.observer.observe(this.element)
    }
    
    disconnect() {
        this.observer.unobserve(this.element)
    }
    
}