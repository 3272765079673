import "bootstrap";

// if(document.getElementById('buy-bitcoin-main')){
//   import('selectize/dist/css/selectize.css');
// }

import { navbar } from "./navbar";
import { bitcoinPricing } from "../plugins/bitcoin_pricing";
import { darkMode } from "../plugins/darkmode";
// import { countdownClock } from "../plugins/countdown_clock";
// import { blockHeight } from "../plugins/get_block";
// import { modalOpen } from "../plugins/modal_open";
// import { exchangeOpen } from "../plugins/exchange_select";
// import { loadSearch } from "../plugins/load_search";
// import { urlCopy } from "../plugins/url_copy";
// import { initSelect2 } from "../plugins/country_select";
// import { linkOpen } from "../plugins/country_select";
// import { bitcoinChartPricing } from "../plugins/bitcoin_price_chart_over_time";
// import { usdToBitcoin } from "../plugins/usd_to_bitcoin";
// import { bitcoinToUsd } from "../plugins/usd_to_bitcoin";
import { initFlash } from "../plugins/init_flash";
// import { miningCalculator } from "../plugins/mining-calculator";
// import { fadeIn } from "../plugins/aos_fade";

import "controllers";
import '@hotwired/turbo-rails';
import 'lazysizes';

// import { exchangePricing } from "../plugins/bitcoin_exchange_price";
// import { nodeMapStart } from "../plugins/nodeMap";
// import { tooltipFunction } from "../plugins/tooltip";
// import { greenValue } from "../plugins/treasury";
navbar();
// modalOpen();
// countdownClock();
// tooltipFunction();
// blockHeight();
// exchangeOpen();
bitcoinPricing();
// loadSearch();
// urlCopy();
// initSelect2();
// linkOpen();
// bitcoinChartPricing();
// usdToBitcoin();
// bitcoinToUsd();
initFlash();
darkMode();
// miningCalculator();
// fadeIn();

// exchangePricing();
// nodeMapStart();
// greenValue();
