const darkMode = () => {
  document.querySelectorAll('input[setter]').forEach(setter => {
    setter.addEventListener('change', () => setDark(setter.checked));
  });
  const setDark = (setter) => {
    const isDark = setter ? 'add' : 'remove';
      document.documentElement.classList[isDark]("darkmode");
      localStorage.setItem('isDark', `${setter}`);
      if (window.location.pathname.match(/terminal[\\s\\S]*/)) {
          document.querySelector("ninja-keys").classList[isDark]("dark")
      }
  }
}
export { darkMode };