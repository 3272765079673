import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize() {
    this.observer = new IntersectionObserver(entries => this.callback(entries), {
      root: this.element.parentElement,
      rootMargin: "40px 0px 0px 0px"
    })
  }

  connect() {
    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.unobserve(this.element)
  }

  callback(entries, _observer) {
    entries.forEach(entry => {
      if (!entry.isIntersecting) return
      this.observer.root.style.pointerEvents = "none";
      fetch(this.element.href, {
        headers: {
          Accept: "text/vnd.turbo-stream.html, application/json, text/html;q=0.9, application/xml;q=0.9, text/plain;q=0.8, */*;q=0.5"
        },
        credentials: "same-origin",
        method: "POST"
      })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
        .then(() => this.element.remove())
        .then(() => this.observer.root.style.pointerEvents = "auto")
    })
  }
}