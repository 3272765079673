
var getPrice = new XMLHttpRequest();

getPrice.open("GET", "https://api.coinbase.com/v2/prices/spot?currency=USD");
getPrice.onreadystatechange = function () {
  if (getPrice.readyState == 4) {
    var ticker = JSON.parse(getPrice.responseText);
    var price = ticker.data.amount;
    var pathLocation = window.location.pathname
    var fullPrice = Math.round(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (!(pathLocation == "/bitcoin-treasuries" || pathLocation == "/terminal/btc-to-usd" || pathLocation == "/terminal/usd-to-btc" || pathLocation == "/mining" || pathLocation == "/terminal/mining-calculator"|| pathLocation == "/satoshi-calculator")) {
      if (window.location.pathname == "/why-is-bitcoin-price-going-up"){
        document.querySelector('#bitcoin-price-pill-article').innerHTML = `$${fullPrice}`
      }
    $('.bitcoin-price').each(async function () {
        $(this).prop('Counter',0).animate({
            Counter: price
        }, {
            duration: 1000,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now).toLocaleString('en'));
            }
        });
    }
  );
  }
  else {
    var fullPrice = Math.round(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    document.querySelector('.bitcoin-price').innerHTML = fullPrice

  }
  }
};

const bitcoinPricing = () => {
  getPrice.send();
};

export { bitcoinPricing };
