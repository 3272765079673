import Highcharts from 'highcharts/highstock';
import moment from 'moment';

let BtcPricesChart = class {
  constructor(options) {
    this.interval = options.interval;
    this.minutes = options.minutes;
    this.hours = options.hours || null;
    this.ytdMode = options.ytdMode || "off";
    this.data = options.data;
  }
  
  setHighchartsGlobalOptions() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
        numericSymbols: null
      },
      global: {
        useUtc: false
      }
    });
  };
  
  getStepValue() {
  	if (this.interval === '1_min') {
      return 10;
    } else if (this.interval === '5_min') {
      return 24;
    } else if (this.interval === '30_min') {
      return 48;
    } else if (this.interval === '3_hour') {
      return 24;
    } else if (this.interval === '1_day') {
      return 30;
    } else if (this.interval === '5_day') {
      return 36;
    } else if (this.interval === '15_day') {
      return 24;
    };
  }
  
  categoriesSorter() { 
    let result = [];
    let momentObj;

    if (this.interval === '1_min') {
      for (let i = 0; i < this.data.length; i++) {
        result.push(moment.unix(this.data[i].t).format('h:mm A'));
      }
    } else if (this.interval === '5_min') {
      for(let j = 0; j < this.data.length; j++) {
        momentObj = moment({ hour: moment.unix(this.data[j].t).hours(), minute: 0 })
        result.push(momentObj.format('h:mm A'));
      }
    } else if (this.interval === '30_min' || this.interval === '3_hour') {
      for(let j = 0; j < this.data.length; j++) {
        result.push(moment.unix(this.data[j].t).format('MMM DD'));
      }
    } else if (this.interval === '1_day' && this.ytdMode === 'on') {
      for(let j = 0; j < this.data.length; j++) {
        result.push(moment.unix(this.data[j].t).format('MMM DD'));
      }
    } else if (this.interval === '1_day' && this.ytdMode === 'off') {
      for(let j = 0; j < this.data.length; j++) {
        result.push(moment.unix(this.data[j].t).format("MMM DD 'YY"));
      }
    } else if (this.interval === '5_day') {
      for(let j = 0; j < this.data.length; j++) {
        result.push(moment.unix(this.data[j].t).format("MMM 'YY"));
      }
    } else if (this.interval === '15_day') {
      for(let j = 0; j < this.data.length; j++) {
        result.push(moment.unix(this.data[j].t).format('YYYY'));
      }
    };
  	return result;
  };

  valueWithSignIndication(value) {
    return (value).toString().includes('-') ? `${value}` : `+${value}`;
  }

  getChangeVariation(previousVal, currentVal, percentMode = false) {
    let finalResult;
  
    finalResult = percentMode ? 
      (((currentVal.y - previousVal.y) / previousVal.y) * 100) :
      (currentVal.y - previousVal.y);
  
    return finalResult.toFixed(2)
  };

	tooltipFormatter (firstDataPoint, currentData) {
    this.percentageChange = this.getChangeVariation(firstDataPoint, currentData, true);
    this.valueChange = this.getChangeVariation(firstDataPoint, currentData);
    this.colorToBeAdded = this.valueChange.toString().includes('-') ? 'red' : 'green';
    return `${currentData.point.date === 'Live Price' ? '<b>Live Price</b>' : ('Date: ' + currentData.point.date)}<br>Price: <b>$ ${currentData.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b><br>Variation: <span id="tooltipVariation" style="color: ${this.colorToBeAdded}"><b>${this.valueWithSignIndication(this.valueChange)} (${this.percentageChange} %)</b></span>`;
  }
  
  produceGraph() {
  	let $this = this;
  	$this.setHighchartsGlobalOptions();
    $this.highchartsGraph = new Highcharts.chart('container', {
        chart: {
          type: 'area',
          spacingLeft: 30,
        },
        accessibility: {
          enabled: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        yAxis: {
          gridLineColor: 'transparent',
          crosshair: {
            width: 1.5,
            color: '#999999',
            dashStyle: 'ShortDash'
          },
          opposite: true,
          title: {
            text: 'Prices'
          }
        },
        xAxis: {
          crosshair: {
            width: 1.5,
            color: '#999999',
            dashStyle: 'ShortDash'
          },
          labels: {
            step: $this.getStepValue()
          },
          categories: $this.categoriesSorter()
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },
        series: [{
          fillOpacity: 0.1,
          threshold: null,
          name: '',
          data: $this.data.map((elem) => ({y: elem.p, date: moment.unix(elem.t).format('MMM DD,YYYY h:mm A'), t: elem.t }))
        }],
        tooltip: {
          formatter: function () {
            return $this.tooltipFormatter($this.highchartsGraph.series[0].data[0], this);
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 2000
            },
            chartOptions: {
              legend: {
                enabled: false
              }
            }
          }]
        }    
    });
  }
}

export { BtcPricesChart }