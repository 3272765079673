const initFlash = () => {

  $(document).ready(function(){
    setTimeout(function(){
      $('#html_flash').remove();
    }, 5000);
  });
};

export { initFlash };
