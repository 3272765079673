import { Controller } from "@hotwired/stimulus";
import { BtcPricesChart } from "../plugins/btc_prices_chart";
import moment from "moment"

export default class extends Controller {
  connect() {
    // console.log("Hello from Stimulus", this.element);
    let chart;
    let shift;
    let lastDataPointTimestamp;

    let realTimePercentageChange;
    let realTimeValueChange;

    let minutesAfter;
    let minutesRemainder;
    let hoursAfter;
    let hoursAfterTimestamp;
    let tempHoursAfter;
    let adjustedTime;

    let container = document.getElementById('container')
    let graphName = document.getElementById('graphName');
    let realTimeChangeContainer = document.getElementById('realTimeChangeContainer');

    let timer;

    let intervalInstanceID;
    let graph1h = document.querySelector('.graph-1h');
    let graph1d = document.querySelector('.graph-1d');
    let graph7d = document.querySelector('.graph-7d');
    let graph1m = document.querySelector('.graph-1m');
    let graph1Y = document.querySelector('.graph-1y');
    let graphYTD = document.querySelector('.graph-ytd');
    let graph5Y = document.querySelector('.graph-5y');
    let graphAll = document.querySelector('.graph-all');

    let binanceWebSocket = new WebSocket('wss:/stream.binance.com:9443/ws/btcusdt@trade');
    let stockObject;
    let stockPrice;
    let stockTradeTime;

    let figureOutMinutesToAddInCurrentPoint = (minutes, interval, divisor) => {
      minutesRemainder = minutes % divisor

      if (interval === '5_min' || interval === '30_min') {
        let j = divisor;
        let k = j

        for (let i = 0; i < k; i++) {
          if (minutesRemainder === i) {
            minutesAfter = moment().add(j, 'minutes').minutes();
            break;
          }
          j -= 1
        }
      }
    };

    let figureOutHoursToAddInCurrentPoint = (hours, timeStamp) => {
      hoursAfterTimestamp = timeStamp;
      hoursAfter = moment.unix(timeStamp).add(hours, 'hours').format('MMM DD,YYYY h:mm A');
    }

    let prepareNextAfterHoursValue = (hoursAfter, hoursValue) => {
      adjustedTime = moment().format('MMM DD,YYYY h:mm A');
      if (adjustedTime === hoursAfter && moment().minutes() === 1) {
        tempHoursAfter = moment.unix(hoursAfterTimestamp).add(hoursValue, hours);
        hoursAfter = tempHoursAfter.format('MMM DD,YYYY h:mm A');
        hoursAfterTimestamp = tempHoursAfter.unix();
        return true;
      } else {
        return false
      }
    };

    let prepareNextAfterMinutesValue = (currentMinutes, currentSeconds, afterMinutes, divisor) => {
      if (currentMinutes % divisor === 0 && currentSeconds === 59) {
        minutesAfter = moment().add(afterMinutes, 'minutes').minutes();
        return true;
      } else {
        return false;
      }
    };

    let checkIfCurrentPointLimitPassed = (currentMinutes, currentSeconds, afterMinutes, interval) => {
      if (interval === '1_min') {
        if (currentMinutes === minutesAfter && currentSeconds === 0) {
          minutesAfter = moment().add(afterMinutes, 'minutes').minutes();
          return true;
        } else {
          return false;
        }
      } else if (interval === '5_min') {
        return prepareNextAfterMinutesValue(currentMinutes, currentSeconds, afterMinutes, 5);
      } else if (interval === '30_min') {
        return prepareNextAfterMinutesValue(currentMinutes, currentSeconds, afterMinutes, 30);
      } else if (interval === '3_hour') {
        return prepareNextAfterHoursValue(hoursAfter, 3);
      } else if (interval === '1_day') {
        return prepareNextAfterHoursValue(hoursAfter, 24);
      } else if (interval === '5_day') {
        return prepareNextAfterHoursValue(hoursAfter, 120);
      } else if (interval === '15_day') {
        return prepareNextAfterHoursValue(hoursAfter, 360);
      }
    };

    let formatMode = (interval, ytdMode) => {
      if (interval === '1_min' || interval === '5_min') {
        return 'h:mm A'
      } else if (interval === '30_min' ||
        interval === '3_hour' ||
        (interval === '1_day' && ytdMode === 'on')) {
        return 'MMM DD'
      } else if (interval === '1_day' && ytdMode === 'off') {
        return "MMM DD 'YY"
      } else if (interval === '5_day') {
        return "MMM 'YY"
      } else if (interval === '15_day') {
        return 'YYYY'
      }
    };

    let valueWithSignIndication = (value) => {
      return (value).toString().includes('-') ? `${value}` : `+${value}`;
    }

    let styleValueWithColor = () => {
      if (realTimeValueChange.toString().includes('-')) {
        realTimeChangeContainer.style.color = 'red';
      } else {
        realTimeChangeContainer.style.color = 'green';
      }
    }

    let addLiveDataPoint = (shift = false) => {
      chart.series[0].addPoint([moment.unix(stockTradeTime).subtract(1, 'minutes').format('h:mm A'), parseFloat(stockPrice)], true, shift);
      chart.series[0].data[chart.series[0].data.length - 1].date = 'Live Price';
      chart.xAxis[0].categories.push('');
      chart.update({
        xAxis: {
          categories: chart.xAxis[0].categories
        }
      })
    };

    let produceBTCLiveChart = (interval, minutes, hours = null, ytdMode = 'off') => {
      fetch(`/api/v1/analytics/btc_prices?interval=${interval}&ytdMode=${ytdMode}`).then((result) => {
        result.json().then((result) => {
          let data = result.data

          chart = new BtcPricesChart({ data, interval, minutes, ytdMode })
          chart.produceGraph();
          chart = chart.highchartsGraph

          binanceWebSocket.addEventListener('message', (event) => {
            stockObject = JSON.parse(event.data);
            stockPrice = stockObject.p;
            stockTradeTime = stockObject.E;
          });

          if (interval === '5_min') {
            figureOutMinutesToAddInCurrentPoint(minutes, interval, 5)
          } else if (interval === '30_min') {
            figureOutMinutesToAddInCurrentPoint(minutes, interval, 30)
          } else if (interval === '3_hour') {
            lastDataPointTimestamp = chart.series[0].data[chart.series[0].data.length - 1].t
            figureOutHoursToAddInCurrentPoint(hours, lastDataPointTimestamp)
          } else if (interval === '1_day') {
            lastDataPointTimestamp = chart.series[0].data[chart.series[0].data.length - 1].t
            figureOutHoursToAddInCurrentPoint(hours, lastDataPointTimestamp);
          } else if (interval === '5_day') {
            lastDataPointTimestamp = chart.series[0].data[chart.series[0].data.length - 1].t
            figureOutHoursToAddInCurrentPoint(hours, lastDataPointTimestamp);
          } else if (interval === '15_day') {
            lastDataPointTimestamp = chart.series[0].data[chart.series[0].data.length - 1].t
            figureOutHoursToAddInCurrentPoint(hours, lastDataPointTimestamp);
          } else if (interval === '1_min') {
            minutesAfter = moment().add(minutes, 'minutes').minutes();
          }

          addLiveDataPoint();

          intervalInstanceID = setInterval(() => {
            graphName.innerHTML = stockPrice ? (Math.round(stockPrice * 100) / 100).toFixed(2) : `<img src="/spinner.svg" width="30px" class="pl-2" />`;
            // Update the live point price
            chart.series[0].points[chart.series[0].points.length - 1].update(parseFloat(stockPrice));
            realTimePercentageChange = (((stockPrice - chart.series[0].data[0].y) / chart.series[0].data[0].y) * 100).toFixed(2);
            realTimeValueChange = (stockPrice - chart.series[0].data[0].y).toFixed(2)
            realTimeChangeContainer.innerHTML = `${valueWithSignIndication(realTimeValueChange)} (${realTimePercentageChange} %)`;
            styleValueWithColor();

            if (checkIfCurrentPointLimitPassed(moment().minutes(), moment().seconds(), minutes, interval)) {
              shift = chart.series[0].data.length > 60
              // Update the last point
              chart.series[0].points[chart.series[0].points.length - 1].update([moment.unix(stockTradeTime).subtract(1, 'minutes').format('h:mm A'), parseFloat(stockPrice)])
              chart.series[0].data[chart.series[0].data.length - 1].date = moment(stockTradeTime + 2000).subtract(1, 'minutes').format('MMM DD,YYYY h:mm A');
              chart.xAxis[0].categories[chart.xAxis[0].categories.length - 1] = moment().subtract(1, 'minutes').format(formatMode(interval, ytdMode))

              chart.update({
                xAxis: {
                  categories: chart.xAxis[0].categories
                }
              });

              addLiveDataPoint(shift);
            }
          }, 1000);

          refreshTimer(120, interval, minutes, hours, ytdMode);
        })
      })
    }

    produceBTCLiveChart('5_min', 5);

    let activateGraphMode = (event) => {
      for (let i = 0; i <= 7; i++) {
        document.querySelectorAll('.modes')[i].classList.remove('bg-gray')
      }
      event.target.classList.add('bg-gray')
      container.innerHTML = '<img src="/spinner.svg" width="100px">'
      clearInterval(intervalInstanceID);
    };

    const graphModes = [graph1h, graph1d, graph7d, graph1m, graph1Y, graphYTD, graph5Y, graphAll];

    let graphModeDetails = [
      // { 'modeName': [interval, mins, hours] }
      { 0: ['1_min', 1] },
      { 1: ['5_min', 5] },
      { 2: ['30_min', 30] },
      { 3: ['3_hour', 180, 3] },
      { 4: ['1_day', 1440, 24] },
      { 5: ['1_day', 1440, 24, 'on'] },
      { 6: ['5_day', 7200, 120] },
      { 7: ['15_day', 21600, 360] }
    ];

    for (let i = 0; i < graphModes.length; i++) {
      graphModes[i].addEventListener('click', (event) => {
        activateGraphMode(event);
        clearInterval(intervalInstanceID);
        clearInterval(timer);
        setTimeout(() => {
          produceBTCLiveChart(...graphModeDetails[i][i]);
        }, 1000);
      });
    };

    let refreshTimer = (seconds, interval, minutes, hours, ytdMode) => {
      timer = setInterval(() => {
        seconds--;
        if (seconds < 0) {
          clearInterval(intervalInstanceID);
          container.innerHTML = '<img src="/spinner.svg" width="100px">'
          clearInterval(timer);
          setTimeout(() => {
            produceBTCLiveChart(interval, minutes, hours, ytdMode);
          }, 1000);
        }
      }, 1000);
    };
  }
}