import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  connect() {
    this.element[this.identifier] = this
    //this.scrollToBottom()
  }

  scrollToBottom() {
    let height = this.element.scrollHeight
    this.element.scrollTo(0, height)
  }
}
