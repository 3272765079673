import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let chatElement = document.querySelector('.msg_box')
    let calcHeight = chatElement.scrollTop + this.element.offsetHeight + 120
    if (calcHeight / chatElement.scrollHeight > 0.85) {
      chatElement.chat.scrollToBottom()
    }
  }
}
